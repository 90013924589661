import React from 'react'
import Layout from '../components/layout'
import SearchInput from '../components/SearchInput'
import Seo from "../components/seo"

const SubscriptionResources = () => {
    return (
        <Layout>
            <Seo title="Subscription Resources" />

            <h1 className="title">Subscription Resources</h1>

            <SearchInput />

            <table>
                <tbody>
                    <tr>
                        <td>LexisNexis Practical Guidance</td>
                        <td><a target="_blank" rel="noreferrer" href="https://advance.lexis.com/">https://advance.lexis.com/</a></td>
                    </tr>

                    <tr>
                        <td>Lexis Advance Research</td>
                        <td><a target="_blank" rel="noreferrer" href="https://advance.lexis.com/pacificresearchhome/">https://advance.lexis.com/pacificresearchhome/</a></td>
                    </tr>

                    <tr>
                        <td>Thomson Reuters (Bodkin: Patent Law in Australia)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://proview.thomsonreuters.com/">https://proview.thomsonreuters.com/</a></td>
                    </tr>

                    <tr>
                        <td>Darts IP</td>
                        <td><a target="_blank" rel="noreferrer" href="https://app.darts-ip.com/darts-web/client/welcome.jsf">https://app.darts-ip.com/darts-web/client/welcome.jsf</a></td>
                    </tr>

                    <tr>
                        <td>Lexology</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.lexology.com/">https://www.lexology.com/</a></td>
                    </tr>
                </tbody>
            </table>
        </Layout>
    )
}

export default SubscriptionResources